import { render, staticRenderFns } from "./Imagen.vue?vue&type=template&id=1ce20388&scoped=true"
import script from "./Imagen.vue?vue&type=script&lang=js"
export * from "./Imagen.vue?vue&type=script&lang=js"
import style0 from "./Imagen.vue?vue&type=style&index=0&id=1ce20388&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ce20388",
  null
  
)

export default component.exports