<template>
    <div>
        <v-card :loading="(cargando)?'orange':null" loader-height="8px" elevation="0">
            <v-toolbar color="#ef6b01" rounded="0" elevation="5">
                <v-btn icon dark @click="atras"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-card-title style="color:#ffff">{{this.rubro}}</v-card-title>
            </v-toolbar>
            <v-responsive class="overflow-y-auto" max-height="85vh" @scroll="onScroll">
            <div style="margin-inline: 15px;" class="mb-12 mt-5" >
                <v-list three-line v-if="this.articulos.length>0" >
                    <template v-for="(item, index) in articulos" >
                        
                        <v-list-item  @change="(agregarCarrito==false)? clicArt(item): ''" :key="item.COD_ART" v-ripple="{ class: 'orange--text' }" >
                        
                        <v-list-item-avatar :rounded="true" size="80px" horizontal>
                                <Imagen v-if="item.imagen" :src="item.imagen" :src2="item.etiqueta"/>
                                <!-- <v-img contain :src="`${item.imagen}`" max-width="160" max-height="160" :key="componentKey"  @error="onErrorImg(item)"/> -->
                        </v-list-item-avatar>

                        <v-list-item-content>
                            {{(item.DESC_CLIENTE)?item.DESC_CLIENTE:item.DESCRIPCION}}
                            <v-list-item-subtitle><span><b>Código: </b><b style="color:orange;font-size:17px">{{item.COD_ART}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle><span><b>Empaque: </b><b style="color:orange;font-size:17px">{{(item.ESCALA!=item.ESC)?item.ESCALA:''}} {{item.CANT_EMPAQ}} {{item.ESC}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="usuario.muestra_lista==1"><span><b>Precio contado: </b><b style="color:orange;font-size:17px">{{precioArt(item) | toCurrency}}</b></span> <span v-if="precioConIva" style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.CANT_EMPAQ!=1 && usuario.muestra_lista==1"><span><b>Precio por empaque contado: </b><b style="color:orange;font-size:17px">{{precioPorEmpaque(item) | toCurrency}}</b> </span><span v-if="precioConIva" style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                            <div style="justify-content: end; display: grid;">
                                <v-btn v-if="(carrito.findIndex(i => i.cod_art==item.COD_ART)==-1)" color="orange" @click="agregarCarrito=true,agregar(item)" elevation="0" outlined small dark style="max-width: 100px;"><v-icon style="font-size:20px">mdi-cart-outline</v-icon>{{(usuario.muestra_lista==1)?'Agregar':'Cotizar'}}</v-btn>
                                <template v-if="(carrito.findIndex(i => i.cod_art==item.COD_ART)!=-1)">
                                    <p style="color:green;margin: 0px;" class="mt-2"><v-icon style="font-size:20px" color="green">mdi-cart-check</v-icon>Agregado al carrito</p>
                                    <v-row class="mt-3" style="margin: auto;">
                                        <v-btn color="orange" fab icon small @click="cantidadMenos(item)"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                        <v-text-field
                                        v-model="carrito[carrito.findIndex(i => i.cod_art==item.COD_ART)].cantidad"
                                        label="Cantidad"
                                        type="number"
                                        dense
                                        flat
                                        solo
                                        hide-details="true"
                                        color="orange"
                                        style="max-width: 95px;align-items: center;"
                                        @click="verDialogCantidad(carrito[carrito.findIndex(i => i.cod_art==item.COD_ART)])"
                                        class="centered-input text-orange"
                                        readonly
                                        ></v-text-field>
                                        <v-btn color="orange" fab icon small @click="cantidadMas(item)"><v-icon>mdi-plus-thick</v-icon></v-btn>
                                    </v-row>
                                    <p class="mt-2" style="color:orange;text-align: center;max-width: 168px;">{{descEmpaque(item)}}</p>
                                </template>
                            </div>
                        </v-list-item-content>
                        
                        </v-list-item>
                        <v-divider :key="index" :inset="item.inset"></v-divider>
            
                    </template>
                </v-list>
                <template v-if="this.articulos.length==0 && !this.cargando">
                    <p style="text-align: center; margin-top:50px" ><v-icon>mdi-information-outline</v-icon> No hay artículos en esta categoría</p>
                </template>
            </div>
            </v-responsive>
            <!--FOOTER---------------->
            <v-footer color="#ef6b01" padless fixed class="mt-5" style="justify-content: end;" rounded="0">
                <v-row justify="center" no-gutters style="height: 44px;">
                    <v-col></v-col>
                    <v-col class="text-center">
                        <v-btn style="bottom: 23px;" fab color="#ef6b01" elevation="20" @click="irHome" class="my-2"><v-icon color="white">mdi-plus-thick</v-icon></v-btn>
                    </v-col>
                    <v-col class="text-end">
                        <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white"><v-icon style="font-size:25px" color="#ffff">mdi-cart</v-icon>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                    </v-col>
                </v-row>
            </v-footer>
                    <!-- <v-col cols="1">
                        <v-menu top v-model="menuFiltro" absolute offset-x :close-on-content-click="false" :close-on-click="true" style="width: 250px">
                            <template v-slot:activator="{on,attrs}">
                                <v-btn v-bind="attrs" large v-on="on" icon color="white" class="ml-2" style="align-items: self-end;height: 39px">
                                    <v-icon style="font-size:25px">mdi-filter-menu</v-icon>
                                </v-btn>
                            </template>
                            <v-list class="text-start">
                                <v-divider class="orange mx-1" dark></v-divider>
                                <v-subheader>Ordenar por</v-subheader>
                                <v-list-item-group v-model="orden" active-class="">
                                    <v-list-item  color="orange">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-action >
                                                <v-checkbox color="orange" :input-value="active"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>Mayor precio</v-list-item-title>
                                            </v-list-item-content>
                                        </template>

                                    </v-list-item>
                                    <v-divider class="mb-2 mx-1"></v-divider>
                                    <v-list-item  color="orange">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-action>
                                                <v-checkbox color="orange" :input-value="active"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>Menor precio</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>

                                </v-list-item-group >
                                <v-divider class="orange mx-1" dark></v-divider>
                                <v-subheader>Filtrar por</v-subheader>
                                <v-list-item-group>
                                    <v-list-item @change="dialogMarca=true, dialogModelo=false, dialogMedida=false,menuFiltro=false">
                                        <v-list-item-content>
                                            <v-list-item-title>Marca</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class=" mb-2 mx-1" ></v-divider>
                                    <v-list-item @change="dialogModelo=true, dialogMarca=false, dialogMedida=false,menuFiltro=false">
                                        <v-list-item-content>
                                            <v-list-item-title>Modelo</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class=" mb-2 mx-1" ></v-divider>
                                    <v-list-item @change="dialogMedida=true, dialogMarca=false, dialogModelo=false, menuFiltro=false">
                                        <v-list-item-content>
                                            <v-list-item-title>Medida</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                                <v-divider class="orange mx-1" dark></v-divider>
                            </v-list>
                        </v-menu>
                    </v-col> -->
                    <!-- <v-col style="display: flex;justify-content: end;">
                        <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white"><v-icon style="font-size:25px" color="#ffff">mdi-cart</v-icon>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                    </v-col>
                </v-row>
            </v-footer> -->
        </v-card>
         <!-- DIALOG CANTIDAD -->
        <v-dialog v-model="dialogCantidad" max-width="300px" persistent>
            <v-card v-if="itemCantSelected">
                <v-toolbar color="#ef6b01">
                    <v-btn icon dark @click="cerrarDialogCambiarCantidad"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <v-card-title style="color:#ffff">Cambiar cantidad</v-card-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="mt-3" v-if="itemCantSelected">
                    <v-text-field autofocus outlined style="font-size: 20px;" v-model="carrito[carrito.findIndex(i => i.cod_art==itemCantSelected.cod_art)].cantidad" label="Cantidad" type="number" solo color="orange"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="itemCantSelected" color="orange" elevation="5" outlined @click="cambiarCantidad()" :disabled="!validarCantidad">Aceptar</v-btn>
                    <v-btn color="grey" elevation="5" @click="cerrarDialogCambiarCantidad" outlined>Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
         <!-- ----CARGANDO------- -->
        <v-overlay :value="cargando">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import ApiServer from '../api';
import Imagen from '../components/Imagen.vue'
import {mapState,mapActions} from 'vuex';
export default {
    name:'ArticulosPorRubro',
    components:{Imagen},
    data(){
        return{
            rubro:null,
            cargando:false,
            articulos:[],
            articulosFiltrados:[],
            marcas:[],
            dialogMarca:false,
            marcasSelected:[],
            modelos:[],
            dialogModelo:false,
            modelosSelected:[],
            medidas:[],
            dialogMedida:false,
            medidasSelected:[],
            agregarCarrito:false,
            menuFiltro:false,
            orden:null,
            menuOtrasOpciones:false,
            itemCantSelected: null,
            dialogCantidad: false,
            }
    },
    methods:{
        ...mapActions(['agregarAlCarrito','editarCantCarrito','borrarArtCarrito']),
        onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
            if (scrollTop + clientHeight >= scrollHeight) {
                // console.log('fin');
            }
        },
        clicArt(item){
            this.$router.push({name: 'Articulo', params: { codArt: item.COD_ART,datosArticulo:item }});
        },
        async agregar(item){
            this.cargando = true
            try {
                let result = await ApiServer.postCarritoCliente({
                    cod_cli:this.usuario.cod_cli,
                    cod_art:item.COD_ART,
                    cantidad: 1,
                    lpr_id:item.LISTA_ID,
                    usuario: this.usuario.nombreUsuario,
                    accion:'CARRITO'
                });
                this.agregarAlCarrito({
                    id:result.insertId,
                    lpr_id:item.LISTA_ID,
                    cod_art:item.COD_ART,
                    art_id:item.ART_ID,
                    desc_cliente: item.DESC_CLIENTE,
                    descripcion: item.DESCRIPCION,
                    mod:item.MOD,
                    med:item.MED,
                    desc_if:item.DESC_IF,
                    cantidad:1,
                    escala_id:item.ESCALA_ID,
                    escala:item.ESCALA,
                    esc:item.ESC,
                    esc_plu:item.ESC_PLU,
                    pr_vta:item.PR_VTA,
                    pr_cto_cpra:item.PR_CTO_CPRA,
                    pr_cto:item.PR_CTO,
                    pr_final:item.PR_FINAL,
                    descuento:item.DESCUENTO,
                    disponible:item.DISPONIBLE,
                    cant_empaq:item.CANT_EMPAQ,
                    ord_rec_str:item.ORD_REC_STR,
                    ean:item.EAN,
                    imagen : `https://loguin.com.ar/img/${item.ART_ID}.jpg`,
                    etiqueta : item.etiqueta
                })
                this.cargando = false
            } catch (error) {
                this.cargando = false
                console.log(error);
            }
            setTimeout(()=>{
                this.agregarCarrito=false
            },1000)
        },
        async cantidadMas(item){
            this.agregarCarrito = true
            this.cargando = true
            try {
                let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
                if((this.carrito[index].cantidad*this.carrito[index].cant_empaq)<item.DISPONIBLE){
                    let cant = this.carrito[index].cantidad+1
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                    this.cargando = false
                    setTimeout(()=>{
                        this.agregarCarrito=false
                    },2000)
                }else{
                    this.cargando = false
                    this.snackbar = true
                    this.snackbarColor ='error'
                    this.snackbarIcon = 'mdi-close-circle'
                    this.snackbarMsge='Sin stock'
                }
                
            } catch (error) {
                console.log(error);
                this.cargando = false
            }
        },
        async cantidadMenos(item){
            this.cargando = true
            this.agregarCarrito=true
            try {
                let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
                if(this.carrito[index].cantidad>1){
                    let cant = this.carrito[index].cantidad-1
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }else{
                    await ApiServer.deleteCarritoCliente(this.carrito[index].id)
                    this.borrarArtCarrito(this.carrito[index].id)
                }
                this.cargando = false
                setTimeout(()=>{
                    this.agregarCarrito=false
                },2000)
            } catch (error) {
                 console.log(error);
                this.cargando = false
            }
        },
        verDialogCantidad(item){
            this.agregarCarrito=true
            this.itemCantSelected = item
            this.dialogCantidad = true
            setTimeout(()=>{
                this.agregarCarrito=false
            },2000)
        },
        async cambiarCantidad(){
            this.dialogCantidad = false
            this.cargando = true
            try {
                let cant = Number(this.itemCantSelected.cantidad)
                if(cant>0 && (cant <= this.itemCantSelected.disponible)){
                    let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
                    
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }
                this.cargando = false
                this.itemCantSelected = null
                
            } catch (error) {
                this.cargando = false
                this.dialogCantidad = false
                this.itemCantSelected = null
                console.log(error);
            }
        },
        cerrarDialogCambiarCantidad(){
            let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
            let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
            if(cant>=this.itemCantSelected.disponible){
                this.carrito[index].cantidad=this.itemCantSelected.disponible/ this.itemCantSelected.cant_empaq  
            }
            if(cant<=0){
                this.carrito[index].cantidad = 1
            }
            this.dialogCantidad = false
            this.itemCantSelected = null
        },
        atras(){
            this.$router.go(-1)
        },
        irCarrito(){
            this.$router.push({name: 'Carrito'});
        },
        irHome(){
            this.$router.push({name: 'Home'});
        },
        descEmpaque(item){
            let cant = this.carrito[this.carrito.findIndex(i => i.cod_art==item.COD_ART)].cantidad
            if(cant==1){
                if(item.ESCALA != item.ESC){
                    return `${cant} ${item.ESCALA} / ${cant*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${cant} ${item.ESCALA}`
                }
            }else{
                if(item.ESCALA != item.ESC){
                    return `${cant} ${item.ESC_PLU} / ${cant*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${cant} ${item.ESC_PLU}`
                }
            }
        },
        porcentajeIva(item){
            //IVA 10.5 o 21
            let x = item.PR_FINAL/item.PR_VTA
            if(Math.abs(21-(x-1)*100)<Math.abs(10.5-(x-1)*100)){
                return 21
            }else{
                return 10.5
            }
        },
        precioArt(item){
            let precio = null
            if(this.precioConIva){
                precio = item.PR_VTA + item.PR_VTA*(item.DESCUENTO/100)
            }else{
                precio = item.PR_FINAL + item.PR_FINAL*(item.DESCUENTO/100)
            }
            return Math.round(precio)
        },
        precioPorEmpaque(item){
            let precio = null
            if(this.precioConIva){
                let pr_vta= item.PR_VTA + item.PR_VTA*(item.DESCUENTO/100)
                precio = Math.round(pr_vta)*item.CANT_EMPAQ
            }else{
                let pr_final = item.PR_FINAL + item.PR_FINAL*(item.DESCUENTO/100)
                precio = Math.round(pr_final)*item.CANT_EMPAQ
            }
            return precio
        }
    },
    computed:{
        ...mapState(['usuario','carrito']),
        validarCantidad(){
            let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
            let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
            if(cant>this.itemCantSelected.disponible || cant<=0){
                return false
            }else{
                return true
            } 
  
        },
        precioConIva(){
            if(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI'){
                return true
            }else{
                return false
            }
        },
    },
    async mounted(){
        if(!this.$route.params.rubroId){
            this.$router.push({name: 'Home'});
        }else{
            this.cargando = true
            this.rubro = localStorage.getItem('lastCategory')
            try {
                this.articulos=[], this.articulosFiltrados=[]
                //BUSCAR EN LAS LISTAS DE PRECIOS DEL CLIENTE
                if(this.usuario && this.usuario.listas.length>0){
                    this.articulos = await ApiServer.getArticulosPorRubro(this.$route.params.rubroId,this.usuario.listas[0].LISTA_ID,this.usuario.cli_id)
                    
                    if(this.usuario.listas.length>1){
                        for (let i = 1; i < this.usuario.listas.length; i++) {
                            let siguenteLista = await ApiServer.getArticulosPorRubro(this.$route.params.rubroId,this.usuario.listas[i].LISTA_ID,this.usuario.cli_id)
                            if(siguenteLista.length>0){
                                siguenteLista.forEach(item => {
                                    if((this.articulos.findIndex(i => i.ART_ID==item.ART_ID))==-1){
                                        this.articulos.push(item)
                                    }
                                });
                            }
                        }
                    }
                }
                let etiquetas = await ApiServer.getEtiquetasProductos()
                this.articulos.forEach(element => {
                    element.imagen=null
                    element.etiqueta = null
                    element.imagen=`https://loguin.com.ar/img/${element.ART_ID}.jpg?t=${(new Date()).getTime()}`
                    if(element.CANT_EMPAQ == 0){
                        element.CANT_EMPAQ = 1
                    }
                    //SI TIENE DESCUENTO
                    // if(element.DESCUENTO && element.DESCUENTO>0){
                    //     element.PR_FINAL = element.PR_FINAL - element.PR_FINAL * (element.DESCUENTO/100)
                    //     element.PR_VTA = element.PR_VTA - element.PR_VTA * (element.DESCUENTO/100)
                    // }
                    let indexEtiqueta = etiquetas.findIndex(x=>x.cod_art==element.COD_ART)
                    if(indexEtiqueta>-1){
                        element.etiqueta = `https://loguin.com.ar/img/imag_etiquetas/${etiquetas[indexEtiqueta].etiqueta}?t=${(new Date()).getTime()}` 
                    }
                });
                // this.articulosFiltrados=this.articulos
                this.cargando = false
                // this.marcas=[],this.medidas=[],this.modelos=[]
                
                for(let art of this.articulos){
                    
                    let indexMarca = this.marcas.findIndex(i => i==art.MARCA)
                    if(indexMarca==-1 && art.MARCA!='')
                    this.marcas.push(art.MARCA)
                    let indexModelo = this.modelos.findIndex(i => i==art.MOD)
                    if(indexModelo==-1 && art.MOD!='')
                    this.modelos.push(art.MOD)
                    let indexMedida = this.medidas.findIndex(i => i==art.MED)
                    if(indexMedida==-1 && art.MED)
                    this.medidas.push(art.MED)
                }
                // this.articulosFiltrados=this.articulos
                //ORDENO POR MAYOR PRECIO
                // this.articulosFiltrados.sort((a, b) => b.PR_FINAL - a.PR_FINAL);
                // this.cargando = false
                console.log('articulos',this.articulos)
            } catch (error) {
                this.cargando = false
                console.log(error);
            }
        }
        
    }
}
</script>

<style scoped>
    .centered-input >>> input {
      text-align: center
    }
    .cant.v-text-field--outlined >>> fieldset {
    border-color: orange;
    border-width: 2px;
    height: 35px;
    align-self: start;
    margin-top: 6px
    }
</style>