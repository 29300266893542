import Vue from 'vue'
import Store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import RubrosHijos from '../views/RubrosHijos.vue'
import ArticulosPorRubro from '../views/ArticulosPorRubro.vue'
import Articulo from '../views/Articulo.vue'
import Carrito from '../views/Carrito.vue'
import Envio from '../views/Envio.vue'
import Pago from '../views/Pago.vue'
import ListaDeArticulos from '../views/ListaDeArticulos.vue'
import ConfirmarOrden from '../views/ConfirmarOrden.vue'
import DetalleCompra from '../views/DetalleCompra.vue'
import Buscador from '../views/Buscador.vue'
import PedidoIngresado from '../views/PedidoIngresado.vue'
import MisPedidos from '../views/MisPedidos.vue'
import CatalogoDePuntos from '../views/CatalogoDePuntos'
import MisCanjes from '../views/MisCanjes.vue'
import MiPerfil from '../views/MiPerfil.vue'
import Cotizaciones from '../views/Cotizaciones.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/Login',
  //   name: 'Login',
  //   component: Login,
  //   beforeEnter: (to, from, next) => {
  //     if(verifyToken( Cookies.get("user_token"))){
  //       next('/')
  //     }
  //   }
  // },
  {
    path: "/",
    redirect: "/Home",
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/buscador',
    name: 'Buscador',
    component: Buscador,
  },
  {
    path: "/rubro/:rubroId",
    name: "RubrosHijos",
    component: RubrosHijos,
  },
  {
    path: "/rubro/articulos/:rubroId",
    name: "ArticulosPorRubro",
    component: ArticulosPorRubro,
  },
  {
    path: "/articulo/:codArt",
    name: "Articulo",
    component: Articulo,
    props: true,
  },
  {
    path: "/listadoarticulos/:agrupador",
    name: "ListaDeArticulos",
    component: ListaDeArticulos,
    props: true,
  },
  {
    path: "/carrito",
    name: "Carrito",
    component: Carrito,
  },
  {
    path: "/envio",
    name: "Envio",
    component: Envio,
  },
  {
    path: "/pago",
    name: "Pago",
    component: Pago,
  },
  {
    path: "/confirmarorden",
    name: "ConfirmarOrden",
    component: ConfirmarOrden
    },
  {
    path: "/detallecompra",
    name: "DetalleCompra",
    component: DetalleCompra,
  },
  {
    path: "/pedidoingresado",
    name: "PedidoIngresado",
    component: PedidoIngresado,
    props: true,
    beforeEnter: (to, from, next) => {
      if(to.params.nroPedido){
        next()
      }else{
        next('/home')
      }
    }
  },
  {
    path: "/mispedidos",
    name: "MisPedidos",
    component: MisPedidos,
  },
  {
    path: "/catalogo",
    name: "Catalogo",
    component: CatalogoDePuntos,
    beforeEnter: (to, from, next) => {
      if(Store.state.usuario && Store.state.usuario.muestraCatalogoPuntos){
        next()
      }else{
        next('/home')
      }
    }
  },
  {
    path: "/miscanjes",
    name: "MisCanjes",
    component: MisCanjes,
    beforeEnter: (to, from, next) => {
      if(Store.state.usuario && Store.state.usuario.muestraCatalogoPuntos){
        next()
      }else{
        next('/home')
      }
    }
  },
  {
    path: "/miperfil",
    name: "MiPerfil",
    component: MiPerfil,
  },
  {
    path: "/cotizaciones",
    name: "Cotizaciones",
    component: Cotizaciones,
  },
]

const router = new VueRouter({

  routes,
  hashbang: false,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})
// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.guest)) {
//     let token = Cookies.get('token')
//     let verif = verifyToken( token)
//     if(!verif){
//       next({ path: '/login'})
//     }else{
//       next()
//     }
//   }else {
//     next()
//   }

// })

// function verifyToken(token){
//   try {
//     if(jwt){
//       let resp = jwt.verify(token, config.jwt.TOKEN_SECRET)
//       return resp
//     }else{
//       return null
//     }
//   } catch (error) {
//     // console.log(error);
//     return null
//   }
// }

export default router
