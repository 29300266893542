<template>
    <!-- <v-container>
        <v-img contain :src="`http://10.19.0.90:8080/api/articulos/imagen/${this.cod}`" 
        max-width="160"
        max-height="160"
        />
        
        
    </v-container> -->
    
        <!-- <v-img contain :src="urlImg" max-width="160" max-height="160" :key="componentKey"  @error="onErrorImg()"/> -->
    <div v-if="this.src2" style="display: grid;">
        <v-img class="img1" contain :src="urlImg2"/>
        <v-img contain :src="urlImg" max-width="160" max-height="160" :key="componentKey"  @error="onErrorImg()"/>
    </div>
    <div v-else style="display: grid;">
        <v-img contain :src="urlImg" max-width="160" max-height="160" :key="componentKey"  @error="onErrorImg()"/>
    </div>


</template>

<script>
export default {
    name: 'Imagen',
    props:{src: {type: String}, src2: {type: String}},
    data(){
        return{
            componentKey:0,
            urlImg: null,
            urlImg2: null,
        }
    },
    methods:{   
        onErrorImg(){
            this.urlImg = require('../assets/default_image.png')
            this.componentKey++//PARA ACTUALIZAR 
        },
        onErrorImg2(){
            this.urlImg2 = require('../assets/default_image.png')
            this.componentKey++//PARA ACTUALIZAR 
        }
    
    },
    mounted(){
        this.urlImg = this.src
        this.urlImg2 = this.src2
    },
    computed:{  
    },
    watch:{
    }

}
</script>

<style scoped>
    .img1{
    position:absolute;
    z-index: 1;
    max-width: 80px;
    max-height: 17px;
    }
</style>