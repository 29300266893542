<template>
  <v-container fluid style="height: 50%" class="d-flex flex-column justify-center align-center">
    <slot name="icon">
        <v-icon color="red" size="80">mdi-close-octagon</v-icon>
    </slot>
    <slot>
        <div style="font-size: x-large;font-weight: 500;">Error de acceso</div>
        <div class="mt-5">
          <v-btn outlined color="primary" rounded @click="recargar">Recargar</v-btn>
        </div>
    </slot>
  </v-container>
</template>

<script>
export default {
    name:'Error',
    methods:{
      recargar(){
         window.location.reload();
      }
    }
}
</script>

<style>

</style>