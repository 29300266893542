<template>
<div>
    <v-card :loading="(cargando)?'orange':null" loader-height="8px" elevation="0">
        <v-toolbar color="#ef6b01" rounded="0" elevation="5" class="fixed-bar">
            <v-btn icon dark @click="atras"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-card-title style="color:white" class="text-truncate">{{rubroPadre}}</v-card-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <div style="margin-inline: 15px;" class="mt-5 mb-8">
            <v-list v-if="this.rubros.length>0"  style="font-size: large;font-weight: 500; line-height: 50px;">
                <template v-for="(item) in rubros" >
                    <v-list-item @change="clicFila(item)" :key="item.RUBRO"  v-ripple="{ class: 'orange--text' }">
                        <v-list-item-avatar :rounded="true" size="80px" horizontal>
                            <!-- <v-img :src="`http://10.19.0.90:8080/api/articulos/artrubro/imagen/${item.RUBRO_ID}`" max-width="100" max-height="100"></v-img> -->
                            <v-img contain :src="`${item.imagen}`" max-width="160" max-height="160" :key="componentKey"  @error="onErrorImg(item)"/>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            {{item.RUBRO}}
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="orange mb-2" :key="item.RUBRO_ID" style="border-width: 1px" dark></v-divider>
                </template>
            </v-list>
        </div>
        <!-- FOOTER -->
        <v-footer color="#ef6b01" padless fixed class="mt-5" style="justify-content: end;" rounded="0">
            <v-row justify="center" no-gutters style="height: 44px;">
                <v-col></v-col>
                <v-col class="text-center">
                    <v-btn style="bottom: 23px;" fab color="#ef6b01" elevation="20" @click="irHome" class="my-2"><v-icon color="white">mdi-plus-thick</v-icon></v-btn>
                </v-col>
                <v-col class="text-end">
                    <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white"><v-icon style="font-size:25px" color="#ffff">mdi-cart</v-icon>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </v-card>  
    <!-- ----CARGANDO------- -->
    <v-overlay :value="cargando">
        <v-progress-circular
            indeterminate
            size="70"
            width="7"
        ></v-progress-circular>
    </v-overlay>
</div>
</template>

<script>
import ApiServer from '../api';
import {mapState} from 'vuex';
export default {
    name:'RubrosHijos',
    data(){
        return{
            rubroPadre:null,
            cargando:false,
            rubros:[],
            componentKey:0

        }
    },
    methods:{
        async clicFila(fila){
            try {
                //COMPROBAR SI HAY QUE MOSTRAR EL LISTADO DE ARTICULOS, O MAS RUBROS HIJOS
                let resp = await ApiServer.getArtRubrosHijos(fila.RUBRO_ID,this.usuario.listas[0].LISTA_ID)
                await ApiServer.postUsoLog({
                    cod_cli: this.usuario.cod_cli,
                    usuario: this.usuario.nombreUsuario,
                    accion: 'FAMILIA',
                    dato: fila.RUBRO
                })
                if(resp.length>0){
                    this.$router.push({name: 'RubrosHijos', params: { rubroId: fila.RUBRO_ID }});  
                }else{
                    localStorage.setItem('lastCategory',fila.RUBRO)
                    this.$router.push({name: 'ArticulosPorRubro', params: { rubroId: fila.RUBRO_ID }});
                }
            } catch (error) {
                console.log(error);
            }
        },
        irCarrito(){
            this.$router.push({name: 'Carrito'});
        },
        irHome(){
            this.$router.push({name: 'Home'});
        },
        atras(){
            this.$router.go(-1)
        },
        async cargar(){
            this.cargando = true
            try {
                let resp = await ApiServer.getArtRubrosHijos(this.$route.params.rubroId,this.usuario.listas[0].LISTA_ID)
                // resp = resp.filter((item) => item.COUNT>0)
                this.rubros = resp
                console.log(this.rubros);
                this.rubros.forEach(element => {
                    element.imagen=`https://loguin.com.ar/img/rubros/${element.RUBRO_ID}.jpg?t=${(new Date()).getTime()}`
                });
                if(this.rubros.length>0)
                this.rubroPadre = this.rubros[0].PADRE
                this.cargando = false
            } catch (error) {
                this.cargando = false
                console.log(error);
            }
        },
        onErrorImg(item){
            item.imagen = require('../assets/default_image.png')
            this.componentKey++//PARA ACTUALIZAR
            
        }
    },
    async mounted(){
        if(!this.$route.params.rubroId){
            this.$router.push({name: 'Home'});
        }
        this.cargar()
        
    },
    computed:{
        ...mapState(['carrito','usuario']),
    },
    created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.cargar()
      }
    )
  },
}
</script>

<style scoped>
    .fixed-bar {
    position: sticky;
    position: -webkit-sticky; /* for Safari */
    top: 0em;
    z-index: 2;
    }
</style>